import React from "react"
import homeStyle from "../styles/home.module.scss"
import { Link } from "gatsby"

const Snippet = ({title, text, link, ...other}) => {
    return (
        <div className={homeStyle.snippet} {...other}>
            <h4><Link to={link} >{title}</Link></h4>
            <p>{text}</p>
        </div>
    )
}

export default Snippet
