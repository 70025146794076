import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";

import Layout from "../components/layout";
import SectionHead from "../components/sectionHead";
import Snippet from "../components/snippet";
import SEO from "../components/seo";
import Post from "../components/post";

import homeStyle from "../styles/home.module.scss";

const Home = (props) => {
    const data = useStaticQuery(graphql`
        query HomeQuery {
            allMarkdownRemark(
                sort: { order: DESC, fields: frontmatter___date }
                limit: 4
            ) {
                edges {
                    node {
                        frontmatter {
                            title
                            date
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `);

    const entries = data.allMarkdownRemark.edges;

    return (
        <Layout>
            <div className={homeStyle.block}>
                <SectionHead text="最新エントリ" />
                {entries.map((edge, i) => (
                    <Post
                        key={i}
                        slug={edge.node.fields.slug}
                        title={edge.node.frontmatter.title}
                        date={edge.node.frontmatter.date}
                    />
                ))}
                <div className={homeStyle.moreButton}>
                    <Link to={`/archives`}>もっと見る</Link>
                </div>
            </div>
            <div className={homeStyle.block}>
                <SectionHead text="ベストヒットたち" />
                <div className={homeStyle.snippets}>
                    <Snippet
                        title="これは水です"
                        link="/archives/this-is-water"
                        text="２００８年に急逝したアメリカの作家David Foster Wallaceの２００５年Kenyon大学卒業スピーチの私訳です。後に別の人によって書籍化されました。"
                    />

                    <Snippet
                        title="「それがリーダーってもんだよ」"
                        link="/archives/16385"
                        text="今でも納得できる、自分のリーダー論を書いてます。なぜかネットで共感を呼びました。半分フィクションです。さてどの半分でしょう？"
                    />

                    <Snippet
                        title="１０年という短期間で英語をマスターする方法"
                        link="/archives/1263"
                        text="初期のエントリで一番読まれたものです。英語を学ぶ姿勢について書きました。執筆から１０年近く経った今も勧められる勉強法です。"
                    />

                    <Snippet
                        title="個人的な偏見"
                        link="/archives/14957"
                        text="初期に書いたブログでは一番気に入っています。文章にしておくと、その時のアタマとココロが永久保存できるからいいですね。"
                    />
                </div>
            </div>
            <SEO useTitleTemplate={false} title="quipped" />
        </Layout>
    );
};

export default Home;
