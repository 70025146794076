import React from "react";
import homeStyle from "../styles/home.module.scss";
import { Link } from "gatsby";
import { imageExists, generateRandBgColor } from "../util/util";

class Post extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showImage: false,
            color: "#FFFFFF",
        };
    }

    componentDidMount = () => {
        imageExists(
            `https://r2-public-worker.quipped.workers.dev/${this.props.slug}.png`,
            (exists) =>
                this.setState({
                    showImage: exists,
                    color: exists ? "#FFFFFF" : generateRandBgColor(),
                })
        );
    };

    render() {
        const { slug, title, date } = this.props;
        return (
            <div
                className={homeStyle.lastEntry}
                style={{
                    backgroundColor: this.state.color,
                }}
            >
                {this.state.showImage && (
                    <img
                        src={`https://r2-public-worker.quipped.workers.dev/${slug}.png`}
                        alt="hero"
                        className={homeStyle.heroImage}
                    />
                )}
                <h2>
                    <Link to={`/archives/${slug}`}>{title}</Link>
                </h2>
                <p>{date}</p>
            </div>
        );
    }
}

export default Post;
