export function imageExists(url, callback) {
    var imageData = new Image();
    imageData.onload = function () {
        callback(true);
    };
    imageData.onerror = function () {
        callback(false);
    };
    imageData.src = url;
}

export function generateRandBgColor() {
    const palette = ["#f8f9fa", "#e9ecef", "#dee2e6", "#ced4da", "#adb5bd"];
    let index = Math.floor(Math.random() * 5);
    return palette[index];
}
